var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "page" },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "response" }, [
            _c("div", { staticClass: "response__title" }, [
              _vm._v("Авто в черном списке!"),
            ]),
            _c("div", { staticClass: "response__text" }, [
              _c("div", [
                _vm._v(
                  _vm._s(_vm.$route.params.error[0].message.split("Вы")[0])
                ),
              ]),
              _c("div", [
                _vm._v(
                  " Вы не можете подтвердить приезд или забронировать место в общей очереди. "
                ),
              ]),
            ]),
          ]),
          _c("MainButton", {
            staticClass: "confirm-button",
            attrs: {
              title: "Понятно",
              type: "primary",
              size: "big",
              width: "full",
            },
            on: {
              "button-clicked": function ($event) {
                return _vm.$router.push({ name: "terminal-carNumber" })
              },
            },
          }),
        ],
        1
      ),
      _c("TechSupport"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }